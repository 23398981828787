import { styled } from '@mui/system'

const Wrapper = styled('footer')`
	padding-bottom: calc(var(--footerActionBarHeight, 0px) + var(--stickyButtonsHeight, 0px));
	overflow: hidden;
	width: 100%;
`
const ParallaxLogoContainer = styled('div')`
	padding: ${({ theme }) => theme.spacing(4, 2)};
`

export { Wrapper, ParallaxLogoContainer }
