import TopArea from './top-area'
import MiddleTopArea from './middle-top-area'
import MiddleBottomArea from './middle-bottom-area'
import BottomArea from './bottom-area'
import { Wrapper, ParallaxLogoContainer } from './footer.styled'
import { string, array, object, node } from 'prop-types'
import { cx } from '@emotion/css'
import { useCmsBlock } from '@bluheadless/ui-logic/src/hooks/cms/useCmsBlock'
import Block from '@bluheadless/ui/src/particles/cms-block/block'

const LogoParallax = () => {
	const { content: cmsBlockContent } = useCmsBlock({ identifier: 'footer-logo' })

	return (
		<div className="parallax-logo-wrapper">
			<ParallaxLogoContainer className="parallax-logo-container">
				{cmsBlockContent?.length > 0 ? <Block content={cmsBlockContent} /> : null}
			</ParallaxLogoContainer>
		</div>
	)
}

const Footer = ({ className, topAreaChildComponent, newsletterProps, copyrightProps, menus = [] }) => {
	return (
		<Wrapper className={cx('Footer-root', className)}>
			<LogoParallax />
			<TopArea childComponent={topAreaChildComponent} />
			<MiddleTopArea menus={menus} newsletterProps={newsletterProps} />
			<MiddleBottomArea />
			<BottomArea copyrightProps={copyrightProps} />
		</Wrapper>
	)
}

Footer.propTypes = {
	/**
	 * Defines a classname for root node
	 */
	className: string,
	/**
	 * Defines topArea Content
	 */
	topAreaChildComponent: node,
	/**
	 * Defines newsletter properties
	 */
	newsletterProps: object,
	/**
	 * Defines the properties of copyright text
	 */

	copyrightProps: object,
	/**
	 * Defines the footer menus array
	 */
	menus: array,
}

export default Footer
