import { styled } from '@mui/system'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};
	border-top: 1px solid ${({ theme }) => theme.palette.primary.main};
	border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
	display: flex;
	flex-direction: column;
	padding-bottom: 30px;

	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		padding-bottom: 0;
	}

	& .BHFooterMenus {
		border-top: 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			border-top: none;
			padding: 0;
		}
		& .BhFooterMenuTitle {
			color: ${({ theme }) => theme.palette.footer.color};
		}
		& .MuiPaper-root {
			padding-top: 0;
			border: none;
			padding-left: ${({ theme }) => theme.spacing(3)};
			padding-right: ${({ theme }) => theme.spacing(3)};

			& .MuiAccordionSummary-content {
				color: ${({ theme }) => theme.palette.footer.color};
			}

			${({ theme }) => theme.breakpoints.up('md')} {
				border-right: 1px solid ${({ theme }) => theme.palette.primary.main};
				padding: 0;
				padding-top: 45px;
			}
		}

		.MuiPaper-root {
			${({ theme }) => theme.breakpoints.up('md')} {
				&:first-of-type {
					padding-left: ${({ theme }) => theme.spacing(2)};
					padding-right: ${({ theme }) => theme.spacing(2)};
				}
				&:not(:first-of-type) {
					padding-left: ${({ theme }) => theme.spacing(3)};
					padding-right: ${({ theme }) => theme.spacing(3)};
				}
			}
		}
	}
	& .BHNewsletter {
		.MuiInputBase-root {
			border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
			border-radius: 0;
		}
		.MuiInputBase-input {
			padding: ${({ theme }) => theme.spacing(1, 0)};
		}
		.MuiButtonBase-root {
			right: 0;
			top: 0;
			width: 50px;
			height: 34px;
			padding: 0;
			border-radius: 0px;
			.MuiSvgIcon-root {
				font-size: ${({ theme }) => theme.typography.pxToRem(20)};
			}
		}

		.newsletter-title {
			> .MuiTypography-root {
				margin-bottom: ${({ theme }) => theme.spacing(2.5)};

				${({ theme }) => theme.breakpoints.up('md')} {
					margin-bottom: ${({ theme }) => theme.spacing(3)};
				}
			}
		}
	}
`

const NewsletterWrapper = styled('div')`
	max-width: 100%;
	order: 1;
	width: 100%;
	padding: ${({ theme }) => theme.spacing(3)};

	${({ theme }) => theme.breakpoints.up('md')} {
		order: 2;
		max-width: 305px;
		padding: ${({ theme }) => theme.spacing(5.625, 3)};
	}
`

export { MiddleTopWrapper, NewsletterWrapper }
