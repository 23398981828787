import { styled } from '@mui/system'

const TopAreaWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgTop};
	${({ theme }) => theme.breakpoints.up('sm')} {
		padding: ${({ theme }) => theme.spacing(4, 2)};
	}
`

export { TopAreaWrapper }
